import { useEffect, useRef, useState } from 'react';
import { Container } from '@carvertical/ui';
import cx from 'classnames';
import Image from 'next/image';
import PARTNERS from 'constants/partners';
import styles from './AffiliateTrustBanner.module.scss';

const LOGOS = PARTNERS.PAGE_LOGOS.affiliate;

const AffiliateTrustBanner = ({ logos = LOGOS }) => {
  const bannerRef = useRef<HTMLDivElement>(null);
  const [shouldAnimate, setShouldAnimate] = useState(false);

  useEffect(() => {
    const checkOverflow = () => {
      const bannerElement = bannerRef.current;

      if (bannerElement) {
        const hasOverflow = bannerElement.scrollWidth > bannerElement.clientWidth;
        setShouldAnimate(hasOverflow);
      }
    };

    checkOverflow();
    window.addEventListener('resize', checkOverflow);

    return () => {
      window.removeEventListener('resize', checkOverflow);
    };
  }, []);

  const renderLogos = (duplicate = false) => (
    <div
      className={cx(styles.logosGroup, shouldAnimate && styles.animated)}
      aria-hidden={duplicate}
    >
      {logos.map(({ height, title, url, width }) => (
        <div key={url} className={styles.imageWrapper}>
          <Image src={url} alt={title} height={height} width={width} />
        </div>
      ))}
    </div>
  );

  return (
    <Container className={cx(styles.root, shouldAnimate && styles.fluid)}>
      <div className={cx(styles.wrapper, shouldAnimate && styles.withFade)} ref={bannerRef}>
        {renderLogos()}

        {shouldAnimate && renderLogos(true)}
      </div>
    </Container>
  );
};

export { AffiliateTrustBanner };
