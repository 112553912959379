import { Button, Container, Heading, Stack, Text } from '@carvertical/ui';
import { AnimatePresence, motion, useInView } from 'framer-motion';
import { useRef } from 'react';
import { useTranslation } from 'next-i18next';
import { NS_AFFILIATE } from 'constants/i18n';
import ExternalLink from 'components/common/ExternalLink';
import ArtDirectedImage from 'components/common/ArtDirectedImage';
import { Section } from '../common/Section';
import { FloatingNotification } from './FloatingNotification';
import { APPLICATION_URL } from './constants';
import overviewImageMobileUrl from './assets/overview-image-mobile.jpg';
import overviewImageDesktopUrl from './assets/overview-image-desktop.jpg';
import avatarManUrl from './assets/avatar-man.jpg';
import avatarWomanUrl from './assets/avatar-woman.jpg';
import OverviewMascotIllustration from './assets/overview-mascot.svg';
import styles from './OverviewSection.module.scss';

const INFO_KEYS = [
  'commissionRate',
  'minimumPayout',
  'cookiePolicy',
  'currentPartnerPayouts',
] as const;

const NOTIFICATIONS_WRAPPER_VARIANTS = {
  visible: {
    opacity: 1,
    transition: { staggerChildren: 0.5 },
  },
  hidden: { opacity: 0 },
};

const OverviewSection = () => {
  const { t } = useTranslation(NS_AFFILIATE);
  const imageRef = useRef<HTMLDivElement>(null);
  const inView = useInView(imageRef, { amount: 'all', once: true });

  const renderHeader = (
    <Heading as="h2" variant="l" textColor="light">
      {t('overviewSection.title')}
    </Heading>
  );

  return (
    <Section color="colorBlue">
      <Container>
        <Stack
          gap={{ mobileUp: 5, desktopUp: 8 }}
          type={{ mobileUp: 'vertical', desktopUp: 'horizontal' }}
          crossAxisAlign="stretch"
        >
          <Stack
            gap={{
              mobileUp: 9,
              tabletPortraitUp: 5,
            }}
            className={styles.column}
            mainAxisAlign="center"
            crossAxisAlign="center"
          >
            <div className={styles.mobileHeader}>{renderHeader}</div>

            <div ref={imageRef} className={styles.imageWrapper}>
              <OverviewMascotIllustration className={styles.illustration} original />

              <ArtDirectedImage
                src={overviewImageMobileUrl}
                width={360}
                height={467}
                className={styles.image}
                // @ts-expect-error TS(2739)
                srcSet={{
                  forTabletPortraitUp: {
                    src: overviewImageDesktopUrl,
                    width: 443,
                    height: 672,
                  },
                }}
              />

              <AnimatePresence>
                {inView && (
                  <motion.div
                    initial="hidden"
                    animate="visible"
                    exit="hidden"
                    variants={NOTIFICATIONS_WRAPPER_VARIANTS}
                  >
                    <FloatingNotification
                      title={t('overviewSection.notifications.woman.title')}
                      avatarUrl={avatarWomanUrl}
                      className={styles.womanNotification}
                    />

                    <FloatingNotification
                      title={t('overviewSection.notifications.man.title')}
                      avatarUrl={avatarManUrl}
                      className={styles.manNotification}
                    />
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </Stack>

          <div className={styles.column}>
            <div className={styles.desktopHeader}>{renderHeader}</div>

            <Stack gap={5} className={styles.content}>
              {INFO_KEYS.map((key) => (
                <Stack key={key}>
                  <Heading textColor="light" as="h3" variant="s">
                    {t(`overviewSection.info.${key}.title`)}
                  </Heading>

                  <Text textColor="light" variant="m">
                    {t(`overviewSection.info.${key}.description`)}
                  </Text>
                </Stack>
              ))}
            </Stack>

            <Button as={ExternalLink} href={APPLICATION_URL} size="l">
              {t('joinNowLabel')}
            </Button>
          </div>
        </Stack>
      </Container>
    </Section>
  );
};

export { OverviewSection };
