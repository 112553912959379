import { Container, Grid, GridItem, Heading, Stack, Text } from '@carvertical/ui';
import { useTranslation } from 'next-i18next';
import { NS_HOME } from 'constants/i18n';
import { TOTAL_MARKETS } from 'constants/markets';
import { Section } from './Section';
import { MarketList } from './MarketList';
import { Map } from './Map';

const MarketSection = () => {
  const { t } = useTranslation(NS_HOME);

  return (
    <Section color="colorBlue">
      <Container>
        <Stack gap={4}>
          <Stack gap={4}>
            <Grid rowGap={3} columnCount={{ mobileUp: 1, desktopUp: 12 }}>
              <GridItem start={3} span={8}>
                <Heading as="h2" variant="l" textColor="light" align="center">
                  {t('marketsSection.title', { count: TOTAL_MARKETS })}
                </Heading>
              </GridItem>
              <GridItem start={3} span={8}>
                <Text variant="m" textColor="light" align="center">
                  {t('marketsSection.description')}
                </Text>
              </GridItem>
            </Grid>

            <MarketList />
          </Stack>

          <Map color="dark" />
        </Stack>
      </Container>
    </Section>
  );
};

export { MarketSection };
