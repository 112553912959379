import { useTranslation } from 'next-i18next';
import { Container, Text, Heading, Stack, Button } from '@carvertical/ui';
import { NS_AFFILIATE } from 'constants/i18n';
import { Link } from 'components/common/Link';
import ExternalLink from 'components/common/ExternalLink';
import { useRouteData } from 'context/RouteDataProvider';
import { APPLICATION_URL } from './constants';
import Illustration from './assets/cta-illustration.svg';
import styles from './AffiliateCtaSection.module.scss';

const AffiliateCtaSection = () => {
  const { t } = useTranslation(NS_AFFILIATE);
  const { pages } = useRouteData();

  return (
    <section>
      <Container className={styles.container}>
        <Stack
          gap={{ mobileUp: 3, tabletPortraitUp: 4 }}
          crossAxisAlign="center"
          mainAxisAlign="center"
        >
          <Stack crossAxisAlign="center" gap={{ mobileUp: 1, tabletPortraitUp: 2 }}>
            <Heading variant="l" as="h2" align="center">
              {t('ctaSection.title')}
            </Heading>

            <Text variant="l" align="center">
              {t('ctaSection.subtitle')}
            </Text>
          </Stack>

          <Stack gap={{ mobileUp: 1, tabletPortraitUp: 2 }} crossAxisAlign="center">
            <Button as={ExternalLink} href={APPLICATION_URL} size="l">
              {t('joinNowLabel')}
            </Button>

            <Link className={styles.link} to={pages['affiliate-program-terms'].path}>
              <Text variant="s" align="center">
                {t('ctaSection.termsOfServiceLabel')}
              </Text>
            </Link>
          </Stack>
        </Stack>

        <Illustration className={styles.illustration} original />
      </Container>
    </section>
  );
};

export { AffiliateCtaSection };
