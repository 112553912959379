import { useTranslation } from 'next-i18next';
import cx from 'classnames';
import { Button, Container, Grid, GridItem, Heading, Stack, Text } from '@carvertical/ui';
import { motion } from 'framer-motion';
import type { ParseKeys } from 'i18next';
import ExternalLink from 'components/common/ExternalLink';
import { isExistingMarket } from 'utils/market';
import { useRouteData } from 'context/RouteDataProvider';
import { Section } from '../common/Section';
import { ImageCarousel } from './ImageCarousel';
import { APPLICATION_URL, LOGIN_URL } from './constants';
import { AffiliateStatsCard, type AffiliateStatsCardProps } from './AffiliateStatsCard';
import styles from './AffiliateHero.module.scss';

const STATS: {
  descriptionKey: ParseKeys<'affiliate'>;
  value: string;
  color: AffiliateStatsCardProps['color'];
  incrementBy?: AffiliateStatsCardProps['incrementBy'];
  hideOnMobile?: boolean;
}[] = [
  {
    descriptionKey: 'heroSection.statsDescription.partners',
    value: '1240+',
    color: 'black',
  },
  {
    descriptionKey: 'heroSection.statsDescription.countries',
    value: '28',
    color: 'lightBlue',
    hideOnMobile: true,
  },
  {
    descriptionKey: 'heroSection.statsDescription.customers',
    value: '3.4 M+',
    color: 'yellow',
    incrementBy: 0.1,
  },
  {
    descriptionKey: 'heroSection.statsDescription.users',
    value: '900 M+',
    color: 'blue',
  },
];

const STATS_VARIANT = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
    },
  },
};

const AffiliateHero = () => {
  const { market, locale } = useRouteData();
  const { t } = useTranslation(['affiliate', 'home', 'auth']);

  const smallTitleMarket = isExistingMarket({
    markets: [{ belgium: 'nl-BE' }, { estonia: 'et' }, { germany: 'de' }, { switzerland: 'de-CH' }],
    market,
    locale,
  });

  return (
    <Section color="colorBlue" className={styles.section}>
      <Container className={styles.loginLinkWrapper}>
        <Text variant="m" textColor="light">
          {t('alreadyAnAffiliateLabel')}{' '}
          <ExternalLink href={LOGIN_URL} className={styles.loginLink}>
            {t('auth:login.action')}
          </ExternalLink>
        </Text>
      </Container>

      <Container>
        <Grid
          columnGap={{
            mobileUp: 2,
            desktopUp: 4,
          }}
          rowGap={6}
          columnCount={{
            mobileUp: 1,
            desktopUp: 12,
          }}
          className={styles.wrapper}
        >
          <GridItem
            span={{
              mobileUp: 1,
              desktopUp: 5,
            }}
          >
            <Stack
              gap={{ mobileUp: 3, desktopUp: 4 }}
              className={cx(styles.content)}
              crossAxisAlign={{
                mobileUp: 'center',
                desktopUp: 'flexStart',
              }}
            >
              <Stack
                gap={{ mobileUp: 1, desktopUp: 2 }}
                crossAxisAlign={{
                  mobileUp: 'center',
                  desktopUp: 'flexStart',
                }}
                className={styles.titleWrapper}
              >
                <Heading as="h1" variant={smallTitleMarket ? 'l' : '2xl'} textColor="light">
                  {t('heroSection.title')}
                </Heading>

                <Text variant="l" textColor="light">
                  {t('heroSection.subtitle')}
                </Text>
              </Stack>

              <Button as={ExternalLink} href={APPLICATION_URL} size="l">
                {t('joinNowLabel')}
              </Button>
            </Stack>
          </GridItem>

          <GridItem
            span={{
              mobileUp: 1,
              desktopUp: 7,
            }}
            className={styles.stats}
            as={motion.div}
            // @ts-expect-error TS(2322)
            variants={STATS_VARIANT}
            initial="hidden"
            animate="visible"
          >
            <ImageCarousel className={styles.imageWrapper} />

            {STATS.map(({ color, descriptionKey, hideOnMobile, incrementBy, value }) => (
              <AffiliateStatsCard
                key={descriptionKey}
                className={cx(styles.statsCard, {
                  [styles.hiddenOnMobile]: hideOnMobile,
                })}
                color={color}
                description={t(descriptionKey)}
                value={value}
                incrementBy={incrementBy}
              />
            ))}
          </GridItem>
        </Grid>
      </Container>
    </Section>
  );
};

export { AffiliateHero };
