import Page from 'components/common/Page';
import { MarketSection } from '../common/MarketSection';
import { AffiliateHero } from './AffiliateHero';
import { AffiliateFeaturesSection } from './AffiliateFeaturesSection';
import { AffiliateTrustBanner } from './AffiliateTrustBanner';
import { AffiliateCtaSection } from './AffiliateCtaSection';
import { AffiliateFaqSection } from './AffiliateFaqSection';
import { OverviewSection } from './OverviewSection';
import { AffiliateStepsSection } from './AffiliateSteps';

const AffiliatePage = () => (
  <Page>
    <AffiliateHero />
    <AffiliateTrustBanner />
    <AffiliateStepsSection />
    <OverviewSection />
    <AffiliateFeaturesSection />
    <AffiliateCtaSection />
    <MarketSection />
    <AffiliateFaqSection />
  </Page>
);

export { AffiliatePage };
