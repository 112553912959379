import { Container, Text, Heading, Stack } from '@carvertical/ui';
import { useTranslation } from 'next-i18next';
import type { ParseKeys } from 'i18next';
import GlobeIllustration from 'assets/illustrations/globe.svg';
import SignupIllustration from 'assets/illustrations/signup.svg';
import WalletIllustration from 'assets/illustrations/wallet.svg';
import { Section } from '../../common/Section';
import { SwipeableSections } from '../common/SwipeableSections';
import styles from './AffiliateSteps.module.scss';

type Step = {
  Component: React.ReactNode;
  titleKey: ParseKeys<'affiliate'>;
  descriptionKey: ParseKeys<'affiliate'>;
};

const STEPS: Step[] = [
  {
    titleKey: 'stepsSection.steps.freeSignup.title',
    descriptionKey: 'stepsSection.steps.freeSignup.description',
    Component: <SignupIllustration className={styles.illustration} />,
  },
  {
    titleKey: 'stepsSection.steps.promote.title',
    descriptionKey: 'stepsSection.steps.promote.description',
    Component: <GlobeIllustration className={styles.illustration} />,
  },
  {
    titleKey: 'stepsSection.steps.earn.title',
    descriptionKey: 'stepsSection.steps.earn.description',
    Component: <WalletIllustration className={styles.illustration} />,
  },
];

const AffiliateStepsSection = () => {
  const { t } = useTranslation('affiliate');

  const steps = STEPS.map(({ Component, descriptionKey, titleKey }, index) => ({
    Component,
    title: t(titleKey),
    description: t(descriptionKey),
    subtitle: t('stepLabel', { step: index + 1 }),
  }));

  return (
    <Section>
      <Container>
        <Stack gap={{ mobileUp: 5, desktopUp: 8 }} crossAxisAlign="center">
          <Stack crossAxisAlign="center" gap={2}>
            <Heading variant="l" as="h2" align="center">
              {t('stepsSection.title')}
            </Heading>

            <Text variant="l" align="center">
              {t('stepsSection.subtitle')}
            </Text>
          </Stack>

          <Stack gap={4} type="horizontal" className={styles.gridSteps}>
            {steps.map(({ Component, description, title }, index) => (
              <Stack
                type="vertical"
                crossAxisAlign="center"
                gap={3}
                key={title}
                className={styles.step}
              >
                {Component}

                <Stack crossAxisAlign="center" type="vertical" gap={1.5}>
                  <Stack crossAxisAlign="center">
                    <Text variant="m+">{t('stepLabel', { step: index + 1 })}</Text>

                    <Heading align="center" variant="s" as="h3">
                      {title}
                    </Heading>
                  </Stack>

                  <Text align="center" variant="l">
                    {description}
                  </Text>
                </Stack>
              </Stack>
            ))}
          </Stack>

          <SwipeableSections steps={steps} className={styles.swipeableSteps} />
        </Stack>
      </Container>
    </Section>
  );
};

export { AffiliateStepsSection };
