import { Stack, Heading, Text } from '@carvertical/ui';
import { animate, motion } from 'framer-motion';
import cx from 'classnames';
import { useEffect, useRef } from 'react';
import styles from './AffiliateStatsCard.module.scss';

type AffiliateStatsCardProps = {
  description: string;
  value: string;
  className?: string;
  color: 'lightBlue' | 'yellow' | 'blue' | 'black';
  incrementBy?: 0.1 | 1;
};

const STAT_VARIANT = {
  hidden: { opacity: 0, scale: 0.8, y: 'var(--offset)' },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.5,
      ease: 'easeOut',
    },
  },
};

const AffiliateStatsCard = ({
  className,
  color,
  description,
  incrementBy = 1,
  value,
}: AffiliateStatsCardProps) => {
  const numericalValue = parseFloat(value);
  const animatedValueRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    const animationEndValue = numericalValue / incrementBy;
    const decimalPlaces = incrementBy === 0.1 ? 1 : 0;

    const formatValue = (animatedValue: number) =>
      (animatedValue * incrementBy).toFixed(decimalPlaces);

    const startAnimation = () => {
      const controls = animate(0, animationEndValue, {
        duration: 3,
        onUpdate(animatedValue) {
          if (animatedValueRef?.current) {
            animatedValueRef.current.textContent = formatValue(animatedValue);
          }
        },
        ease: 'easeInOut',
      });

      return () => controls.stop();
    };

    const timeoutId = setTimeout(startAnimation, 250);

    return () => clearTimeout(timeoutId);
  }, [numericalValue, incrementBy]);

  return (
    <Stack
      gap={2}
      className={cx(styles.root, styles[color], className)}
      crossAxisAlign="center"
      mainAxisAlign="center"
      as={motion.div}
      // @ts-expect-error TS(2322)
      variants={STAT_VARIANT}
    >
      <Heading as="h3" variant="m" textColor="inherited" align="center">
        <span ref={animatedValueRef} />
        {value.replace(numericalValue.toString(), '')}
      </Heading>

      <div className={styles.divider} />

      <Text variant="m" textColor="inherited">
        {description}
      </Text>
    </Stack>
  );
};

export { AffiliateStatsCard };
export type { AffiliateStatsCardProps };
