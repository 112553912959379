import { useTranslation } from 'next-i18next';
import { FaqSection } from 'components/common/sections/FaqSection';
import { NS_AFFILIATE } from 'constants/i18n';

const FAQ_ITEMS = [
  {
    question: 'What are the requirements to be an affiliate?',
    content:
      'To become a carVertical affiliate, all you need is to have a channel (website, social media profile, etc.) via which you’ll promote the carVertical product to the relevant audience in the automotive industry field.',
  },
  {
    question: 'Are there any joining fees?',
    content:
      'The carVertical Affiliate Program is completely free – you won’t have to pay any fees at any time. Once you sign-up and get approved - you will receive a bonus of 10 EUR!',
  },
  {
    question: 'How do I know I will be paid for my referral?',
    content:
      'The program is powered by PostAffiliatePro, the leading affiliate tracking software. PostAffiliatePro uses a combination of cookies and IP address to track referrals for the best possible reliability. When the visitor follows your affiliate link to our site, our affiliate system registers this referral and places a cookie on their browser. When the visitor pays for the product, the affiliate system checks for the cookie (if not found, checks for IP address of referral) and credits your account with the commission. This process is absolutely automatic. All your referrals will be properly tracked.',
  },
  {
    question: 'How and when will I be paid? Are there any limits on how much I can earn?',
    content:
      'Your commissions will be paid on the 15th of each month for the previous month. The minimum payout is 50 EUR. We’ll transfer your commission to your bank account or PayPal account. Your bank account details or PayPal email address will need to be filled in during the affiliate sign-up process, and you can check and edit them at any time on your affiliate dashboard. <br>There are no limits for your earnings! The more sales you refer - the more you earn!',
  },
  {
    question:
      'What if the user doesn’t make a purchase instantly? Do I still receive a commission?',
    content:
      'Yes! Our Affiliate system places a cookie on the user’s browser for 90 days. This means that if the user clicks on your link, any purchase made by them is referred to as yours and the commission is calculated for you for 90 days from the last click.',
  },
  {
    question: 'Can I promote you through pay-per-click search engines?',
    content:
      'Unfortunately, no. This way of promotion is relatively expensive, meaning the sale would cost you a similar amount as your affiliate commission. <br> Also, it would be difficult for you to start as we have our internal marketing team working on pay-per-click search engines, so it would be best for you to choose different advertising methods. There are some exceptions, you’ll need to contact your account manager about that.',
  },
  {
    question: 'What is sub-affiliate and how to get sub-affiliates?',
    content:
      "Sub-affiliate is another carVertical affiliate who registered to the carVertical Affiliate Program with your recommendation (using a unique affiliate registration link with your unique ID). You can find your unique sub-affiliate invitation link on your dashboard. <br>Affiliate who has sub-affiliates receives a 5% commission of each sub-affiliate's sale value. The more sub-affiliates the affiliate has, the more passive income can be generated.",
  },
];

const AffiliateFaqSection = () => {
  const { t } = useTranslation(NS_AFFILIATE);

  const faqItems = FAQ_ITEMS.map(({ content, question }, index) => ({
    id: `affiliate-faq-${index}`,
    headingLevel: 'h3' as const,
    buttonNode: question,
    contentNode: (
      <span
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: content,
        }}
      />
    ),
  }));

  return <FaqSection sectionVariant="l" items={faqItems} title={t('faqSection.title')} />;
};

export { AffiliateFaqSection };
