import { Container, Grid, Heading, Stack, Text } from '@carvertical/ui';
import { useTranslation } from 'next-i18next';
import NoCapIcon from 'assets/illustrations/no-cap.svg';
import RealTimeTrackingIcon from 'assets/illustrations/real-time-tracking.svg';
import AccountManagerIcon from 'assets/illustrations/account-manager.svg';
import CommisionsIcon from 'assets/illustrations/commisions.svg';
import ResourcesIcon from 'assets/illustrations/resources.svg';
import FreeToJoinIcon from 'assets/illustrations/free-to-join.svg';
import { Section } from '../common/Section';
import styles from './AffiliateFeaturesSection.module.scss';

const FEATURES = [
  {
    key: 'noCap',
    Icon: NoCapIcon,
  },
  {
    key: 'realTimeTracking',
    Icon: RealTimeTrackingIcon,
  },
  {
    key: 'accountManager',
    Icon: AccountManagerIcon,
  },
  {
    key: 'commisions',
    Icon: CommisionsIcon,
  },
  {
    key: 'resources',
    Icon: ResourcesIcon,
  },
  {
    key: 'freeToJoin',
    Icon: FreeToJoinIcon,
  },
] as const;

const AffiliateFeaturesSection = () => {
  const { t } = useTranslation('affiliate');

  return (
    <Section>
      <Container>
        <Stack gap={{ mobileUp: 5, desktopUp: 8 }}>
          <Stack className={styles.title} gap={2}>
            <Heading variant="l" as="h2">
              {t('featuresSection.title')}
            </Heading>

            <Text variant="l">{t('featuresSection.subtitle')}</Text>
          </Stack>

          <Grid
            className={styles.list}
            columnCount={{
              mobileUp: 1,
              tabletPortraitUp: 2,
              desktopUp: 3,
            }}
            rowGap={{
              mobileUp: 5,
              tabletPortraitUp: 8,
            }}
            columnGap={{
              mobileUp: 3,
              tabletPortraitUp: 6,
              desktopUp: 8,
            }}
          >
            {FEATURES.map(({ Icon, key }) => (
              <Stack as="article" key={key}>
                <Icon className={styles.icon} />

                <Stack gap={2}>
                  <Heading variant="s" as="h3">
                    {t(`featuresSection.features.${key}.title`)}
                  </Heading>

                  <Text variant="m" as="p">
                    {t(`featuresSection.features.${key}.description`)}
                  </Text>
                </Stack>
              </Stack>
            ))}
          </Grid>
        </Stack>
      </Container>
    </Section>
  );
};

export { AffiliateFeaturesSection };
