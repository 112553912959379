import cx from 'classnames';
import { theme } from '@carvertical/ui';
import type { PropsWithChildren } from 'react';
import styles from './Section.module.scss';

type PaddingVariant =
  | 'regular'
  | 'regular tight'
  | 'regular none'
  | 'tight'
  | 'tight regular'
  | 'tight none'
  | 'none'
  | 'none regular'
  | 'none tight';

type SectionProps = {
  className?: string;
  padding?: PaddingVariant;
  responsive?: boolean;
  color?: string;
};

const PADDING_CLASS_MAP = new Map<PaddingVariant, string[]>([
  ['regular', [styles.regularTop, styles.regularBottom]],
  ['regular tight', [styles.regularTop, styles.tightBottom]],
  ['regular none', [styles.regularTop]],
  ['tight', [styles.tightTop, styles.tightBottom]],
  ['tight regular', [styles.tightTop, styles.regularBottom]],
  ['tight none', [styles.tightTop]],
  ['none', []],
  ['none regular', [styles.regularBottom]],
  ['none tight', [styles.tightBottom]],
]);

const Section = ({
  className,
  color = theme.colorWhite,
  padding = 'regular',
  responsive = false,
  ...props
}: PropsWithChildren<SectionProps>) => {
  const paddingClassname = PADDING_CLASS_MAP.get(padding);

  return (
    <section
      className={cx(styles.root, paddingClassname, responsive && styles.responsive, className)}
      style={{ background: color }}
      {...props}
    />
  );
};

export { Section };
export type { SectionProps };
