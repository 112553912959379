import { NS_AFFILIATE, NS_AUTH, NS_HOME } from 'constants/i18n';
import { createStaticRoute } from 'modules/prerender/createRoute';

const { getStaticPaths, getStaticProps } = createStaticRoute({
  id: 'affiliate-program',
  translateNamespace: [NS_HOME, NS_AFFILIATE, NS_AUTH],
  getData: () => ({
    preventGeoModal: true,
  }),
});

export { AffiliatePage as default } from 'components/landings/AffiliatePage';
export { getStaticPaths, getStaticProps };
