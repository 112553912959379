import { motion } from 'framer-motion';
import cx from 'classnames';
import Image from 'next/image';
import { Text } from '@carvertical/ui';
import type { PropsWithChildren } from 'react';

type FloatingNotificationProps = {
  title: string;
  avatarUrl: string;
  description?: string;
  className?: string;
};

const VARIANTS = {
  hidden: { y: 100, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 1,
      type: 'spring',
      bounce: 0,
    },
  },
};

const FloatingNotification = ({
  avatarUrl,
  className,
  description,
  title,
}: PropsWithChildren<FloatingNotificationProps>) => (
  <motion.div
    variants={VARIANTS}
    className={cx('shadow flex items-center gap-2 rounded-s bg-white p-2', className)}
  >
    <Image src={avatarUrl} alt="" width={40} height={40} />

    <div>
      <Text variant="m+">{title}</Text>

      {description && <Text variant="s">{description}</Text>}
    </div>
  </motion.div>
);

export { FloatingNotification };
