import { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import cx from 'classnames';
import Image from 'next/image';
import heroImage1 from './assets/hero-image-1.jpg';
import heroImage2 from './assets/hero-image-2.jpg';
import heroImage3 from './assets/hero-image-3.jpg';
import styles from './ImageCarousel.module.scss';

type ImageCarouselProps = {
  className?: string;
};

const DELAY_START = 1500;
const INTERVAL_DURATION = 3500;
const IMAGES = [heroImage1, heroImage2, heroImage3];
const IMAGE_VARIANTS = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,

    transition: {
      ease: 'easeOut',
      duration: 1,
    },
  },
  exit: {
    opacity: 0,

    transition: {
      duration: 1,
      ease: 'easeIn',
    },
  },
};

const ImageCarousel = ({ className }: ImageCarouselProps) => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    let intervalId: ReturnType<typeof setInterval> | null = null;

    const timeoutId = setTimeout(() => {
      intervalId = setInterval(() => {
        setIndex((prevIndex) => (prevIndex + 1) % IMAGES.length);
      }, INTERVAL_DURATION);
    }, DELAY_START);

    return () => {
      clearTimeout(timeoutId);
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, []);

  return (
    <div className={cx(styles.root, className)}>
      <AnimatePresence>
        <motion.div
          key={index}
          className={cx(styles.imageWrapper)}
          variants={IMAGE_VARIANTS}
          initial="hidden"
          animate="visible"
          exit="exit"
          transition={{ duration: 0.5 }}
        >
          <Image
            src={IMAGES[index]}
            alt={`heroImage${index + 1}`}
            fill
            className={styles.image}
            priority
          />
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export { ImageCarousel };
